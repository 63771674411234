import { PlusIcon, ArrowUpRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { ReactElement } from 'react-markdown/lib/react-markdown';

interface EmptyStateProps {
    title?: string;
    subtitle?: string;
    customIcon?: ReactElement;
    hasIcon?: boolean;
    onClick?: () => void;
    onLinkClick?: () => void;
    actionIcon?: ReactElement;
    actionText?: string;
    pathRoute?: string;
    noPadding?: boolean
}

export default function EmptyState({ title, subtitle, customIcon, hasIcon = true, onLinkClick, onClick, actionIcon, actionText, pathRoute, noPadding = false }: EmptyStateProps) {
    return (
        <div className={clsx('flex justify-center items-center flex-1', noPadding ? '' : ' h-[450px]')}>
            <div className="flex flex-col text-center justify-center h-[200px] font-secondary normal-case">
                {customIcon || (
                    hasIcon ? (
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                vectorEffect="non-scaling-stroke"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                        </svg>
                    ) : null
                )}
                {title ? <h3 className="mt-2 text-sm font-secondary fw-500 normal-case font-semibold text-gray-900">{title}</h3> : null}
                {subtitle ? <p className="mt-1 text-sm text-gray-500">{subtitle}</p> : null}
                {onClick ? (
                    <div className="mt-6">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-secondary normal-case text-white shadow-sm hover:text-grey focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            onClick={onClick}
                        >
                            {actionIcon || <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
                            {actionText || 'Add'}
                        </button>
                    </div>

                ) : pathRoute ? (
                    <div className="mt-6">
                        <Link
                            prefetch={false}
                            onClick={onLinkClick}
                            href={pathRoute}
                            className="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-secondary normal-case text-white shadow-sm hover:text-grey focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            {actionIcon || <ArrowUpRightIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
                            {actionText || 'View'}
                        </Link>
                    </div>

                ) : null}
            </div>
        </div>
    );
}
