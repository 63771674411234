import { BidDto, UserBankAccount, UserCard } from '@/api';

export type PaymentMethodOption = {
    id: string;
    type: BidDto.PaymentMethodTypeEnum;
    label: string;
    brand: string;
};

export function PaymentMethodToOption(
    type: BidDto.PaymentMethodTypeEnum.Card,
    method: UserCard
): PaymentMethodOption;
export function PaymentMethodToOption(
    type: BidDto.PaymentMethodTypeEnum.BankAccount,
    method: UserBankAccount
): PaymentMethodOption;
export function PaymentMethodToOption(
    type: BidDto.PaymentMethodTypeEnum,
    method: UserCard | UserBankAccount
): PaymentMethodOption {
    if (type === BidDto.PaymentMethodTypeEnum.Card) {
        return {
            label: `${(method as UserCard).brand.toUpperCase()} Ending in ${
                method.last4
            }`,
            id: method.id,
            type,
            brand: (method as UserCard).brand,
        };
    }
    return {
        label: `${(
            method as UserBankAccount
        ).bank_name.toUpperCase()} Ending in ${method.last4}`,
        id: method.id,
        type,
        brand: (method as UserBankAccount).bank_name,
    };
}
