import { OfferTransaction } from '@/api';
import { getShippingLabelFn } from '@/queries/offer';
import toast from 'react-hot-toast';
import saveAs from 'file-saver';
import { ErrorHandler } from './ErrorHandler';

export function printShippingLabel(transation: OfferTransaction) {
    return getShippingLabelFn(transation.id).then((data) => {
        const blob = new Blob([Buffer.from(data.buffer, 'base64')], { type: 'application/pdf' });
        saveAs(blob, 'label.pdf');
        return null;
    }).catch(async (err) => {
        const exception = await ErrorHandler.transformError(err);
        toast.error(exception.message);
    });
}
