import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { ControlProps, MenuListProps, components } from 'react-select';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x.svg';
import PaymentMethodIcon from 'public/assets/icons/payment-method.svg';
import { Auction, AuctionBid, BidDto, CreditLimitDto, Item, PaymentAccountDto, UserBankAccount, UserCard } from '@/api';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';
import clsx from 'clsx';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import useAuth from '@/contexts/AuthContext';
import Link from 'next/link';
import { motion } from 'framer-motion';
import BidTimer from '@/components/general/BidTimer';
import { Constants } from '@/helpers/Constants';
import THDialogWrapper from '../../../general/THDialogWrapper';
import Button from '../../../general/Button';
import { PaymentMethodOption, PaymentMethodToOption } from '../../../../types/PaymentOptions';
import { useMobile } from '../../../../hooks/useMobile';
import useAuction from '../../../../hooks/useAuction';
import { useCurrency } from '../../../../hooks/react-currency-hook';
import Price from '../../../general/Price';
import { useAlert } from '../../../../providers/AlertProvider';

type ProductBidModalProps = {
    onHide: () => void;
    show: boolean;
    product: Item | undefined;
    accounts: PaymentAccountDto | undefined;
    creditLimit: CreditLimitDto | undefined;
    allowVipBidding?: boolean;
};

const NoOptionsMessage = () => {
    const searchParams = useSearchParams();
    const pathname = usePathname();
    return (
        <Link href={{ pathname: Constants.Links.LINK_SETTINGS_PAYMENT_METHOD, query: { redirect: `${pathname}?${searchParams.toString()}` } }}>
            <div className="flex w-full items-center pl-[15px] py-[7px]">
                <PaymentMethodIcon className="w-[42px] h-[24px] mr-[8px] inline-block" />
                <span className="w-full flex text-[14px] font-[500]">{i18next.t('button-text.add-payment-method')}</span>
            </div>
        </Link>
    );
};

const Control = (props: ControlProps<PaymentMethodOption>) => {
    const { getValue, children } = props;
    const selectedField = getValue()[0];

    return !selectedField ? null : (
        <components.Control {...props}>
            <div className="flex w-full pl-[15px]">
                <img width="36" alt={selectedField.brand} height="24" src={`/assets/icons/credit-cards/${selectedField.type !== BidDto.PaymentMethodTypeEnum.Card ? 'bank' : selectedField.brand}.svg`} /> <span className="w-full flex text-[14px] font-[500]">{children}</span>
            </div>
        </components.Control>
    );
};

const MenuList = (
    props: MenuListProps<PaymentMethodOption, false>
) => {
    const { getValue, options, selectOption } = props;
    const selectedField = getValue()[0];
    return (
        <components.MenuList {...props}>
            {options.map((optionOrGroup) => {
                const option = optionOrGroup as PaymentMethodOption; // not using groups at the moment
                const isActive = option.id === selectedField.id;
                return (
                    <div key={`PaymentDDItem-${option.id}`} className={clsx('hover:bg-grey16')} onClick={() => selectOption(option)}>
                        <div className="flex  items-center py-[20px] mx-[20px] border-b-[1px] border-grey14">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex items-center">
                                    <img width="36" className="mr-[8px]" alt={option.brand} height="24" src={`/assets/icons/credit-cards/${option.type !== BidDto.PaymentMethodTypeEnum.Card ? 'bank' : option.brand}.svg`} />
                                    <span className={clsx('text-[14px] font-[500]', isActive ? 'text-black' : 'text-grey15')}>{option.label}</span>
                                </div>
                                <div className={clsx('border-[2px] w-[20px] h-[20px] rounded-full  flex justify-center items-center', isActive ? 'border-black' : 'border-grey15')}>
                                    {isActive && <div className={clsx('bg-black rounded-full w-[11px] h-[11px]')} />}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </components.MenuList>
    );
};

const ProductBidModal = (props: ProductBidModalProps) => {
    const isMobile = useMobile();
    const { show: showAlert } = useAlert();
    const router = useRouter();
    const { user } = useAuth();
    const pathname = usePathname();
    const { creditLimit, onHide, accounts, product, show, allowVipBidding } = props;
    const { auction, suggestedBid, placeBid, loading, auctionOver, lastBid, isWinning, hasUserAuctionAccess, endTime } = useAuction(product, undefined, true);
    const { onChange, toNumber, format } = useCurrency({ currency: 'USD', locale: 'en-US', precision: 0, negative: 'never' });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodOption | null>(null);
    const [customBid, setCustomBid] = useState<boolean>(false);
    const [customBidValue, setCustomBidValue] = useState<string | null>(null);
    const [suggestedBidStatic, setSuggestedBidStatic] = useState<string>('$0.00');

    useEffect(() => {
        if (show && suggestedBidStatic === '$0.00') {
            setSuggestedBidStatic(format(Number(suggestedBid).toFixed(0).toString()));
        }
    }, [show, format, suggestedBidStatic, suggestedBid]);

    useEffect(() => {
        if (!show) {
            setTimeout(() => setSuggestedBidStatic('$0.00'), 500);
        }
    }, [show]);

    const buttonText = useMemo(() => {
        if (auctionOver) {
            return i18next.t('button-text.auction-ended');
        }
        return i18next.t('button-text.place-bid');
    }, [auctionOver]);

    const bidTooLow = useMemo(() => {
        if (customBid && customBidValue) {
            if (isWinning && lastBid && lastBid.max_amount) {
                return Number(lastBid?.max_amount) > toNumber(customBidValue);
            }
            return Number(auction?.min_next_bid) > toNumber(customBidValue);
        }
    }, [auction?.min_next_bid, customBid, customBidValue, isWinning, lastBid, toNumber]);

    const bidExceedsLimit = creditLimit && !creditLimit.unlimited && customBid && customBidValue && toNumber(customBidValue) > Number(creditLimit.available_limit);

    const placebidButton = useMemo(() => {
        return (
            <Button
                fullWidth
                className="whitespace-nowrap"
                loading={loading}
                disabled={loading || auctionOver || bidTooLow === true || bidExceedsLimit === true}
                onClick={async () => {
                    if ((!selectedPaymentMethod?.id || selectedPaymentMethod.id === '') && !allowVipBidding) {
                        showAlert({ text: i18next.t('error-text.bidding-error'), animation: 'AlertAnimation', buttonText: i18next.t('button-text.got-it'), description: i18next.t('payment.choose-payment-method'), showButton: true });
                    } else {
                        try {
                            await placeBid(customBid ? toNumber(customBidValue || '0').toFixed(2).toString() ?? '0' : toNumber(suggestedBidStatic).toFixed(2).toString(), selectedPaymentMethod?.id, selectedPaymentMethod?.type);
                            setCustomBid(false);
                            setCustomBidValue(null);
                            onHide();
                            // eslint-disable-next-line no-empty
                        } catch (error) {}
                    }
                }}>{(buttonText)}
            </Button>
        );
    }, [
        auctionOver, bidExceedsLimit, bidTooLow, buttonText,
        customBid, customBidValue, loading, onHide,
        placeBid, selectedPaymentMethod?.id, selectedPaymentMethod?.type,
        showAlert, suggestedBidStatic, toNumber, allowVipBidding
    ]);

    useEffect(() => {
        if (lastBid) {
            if (lastBid?.payment_method) {
                if (lastBid.payment_method === AuctionBid.PaymentMethodEnum.BankAccount) {
                    const lastUsedAccount = accounts?.bank_accounts.find((c) => c.id === lastBid.payment_method_id);
                    if (lastUsedAccount) {
                        setSelectedPaymentMethod(PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.BankAccount, lastUsedAccount));
                        return;
                    }
                } else if (lastBid.payment_method === AuctionBid.PaymentMethodEnum.Card) {
                    const lastUsedCard = accounts?.cards.find((c) => c.id === lastBid.payment_method_id);
                    if (lastUsedCard) {
                        setSelectedPaymentMethod(PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.Card, lastUsedCard));
                        return;
                    }
                }
            }
        }

        if (!selectedPaymentMethod) {
            const defaultBankAccount = accounts?.bank_accounts.find((c) => c.is_default);
            const defaultCard = accounts?.cards.find((c) => c.is_default);

            if (defaultBankAccount) {
                setSelectedPaymentMethod(PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.BankAccount, defaultBankAccount));
            } else if (defaultCard) {
                setSelectedPaymentMethod(PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.Card, defaultCard));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, lastBid]);

    const renderPaymentMethods = useCallback(() => {
        if (accounts === undefined) {
            return (
                <Skeleton className="flex flex-row justify-start items-center border-[1px] my-[2px] rounded-full p-[3px]" height={46} borderRadius={99999} />
            );
        }

        const options = accounts?.cards.map((card: UserCard) => {
            return PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.Card, card);
        }).concat(accounts?.bank_accounts.map((bank: UserBankAccount) => {
            return PaymentMethodToOption(BidDto.PaymentMethodTypeEnum.BankAccount, bank);
        }));

        return (
            <div className="flex flex-row justify-start items-center border-[1px] my-[2px] rounded-full p-[3px] cursor-pointer">
                <Select<PaymentMethodOption>
                    components={options.length > 0 ? { Control, MenuList } : { NoOptionsMessage }}
                    placeholder={allowVipBidding ? i18next.t('payment.payment-method-vip') : i18next.t('button-text.payment-method')}
                    value={selectedPaymentMethod}
                    onChange={(option) => {
                        setSelectedPaymentMethod(option);
                    }}
                    getOptionValue={(option) => option.label}
                    isSearchable={false}
                    options={options}
                    styles={{
                        container: (base) => ({
                            ...base,
                            width: '100%',
                            borderRadius: '1px',
                            cursor: 'pointer!important'
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '0 !important',
                            boxShadow: '0 !important',
                            '&:hover': {
                                border: '0 !important'
                            },
                            // textDecoration: 'underline',
                            background: 'transparent'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #D0D5DD',
                            overflow: 'hidden',
                            borderRadius: 10,
                            width: isMobile ? '80vw' : 360,
                            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.20)',

                        })
                    }}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#D0D5DD',
                            primary: '#D0D5DD',
                        },
                    })}
                />
            </div>
        );
    }, [accounts, isMobile, selectedPaymentMethod, allowVipBidding]);

    const handleCreditLimitAlert = useCallback(() => {
        if (user?.onboarding_status === 'done') {
            showAlert({
                text: i18next.t('alert-text.credit-limit-increase.title'),
                action: () => { window.open('https://support.therealest.com', '_blank'); },
                description: i18next.t('alert-text.credit-limit-increase.subtitle'),
                animation: 'WarningAnimation',
                buttonText: i18next.t('alert-text.credit-limit-increase.button-text'),
                showButton: true
            });
        } else {
            showAlert({
                text: i18next.t('alert-text.complete-onboarding.title'),
                action: () => { router.push(`/onboarding?returnUrl=${pathname}`); },
                description: i18next.t('alert-text.complete-onboarding.subtitle'),
                animation: 'WarningAnimation',
                buttonText: i18next.t('alert-text.complete-onboarding.button-text'),
                showButton: true
            });
        }
    }, [pathname, router, showAlert, user?.onboarding_status]);

    return (
        <Transition.Root show={show} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-10"
                onClose={() => {
                    // onHide();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity " />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative  transform  rounded-[8px] bg-white p-[40px] pt-[10px] shadow-xl transition-all w-full max-w-[440px] h-auto ">
                                <>
                                    <button
                                        className="cursor-pointer"
                                        onClick={() => {
                                            onHide();
                                            setCustomBid(false);
                                            setCustomBidValue(null);
                                        }}><XIcon className="absolute right-[15px] top-[15px]" />
                                    </button>
                                    <div className="space-y-[20px]">
                                        <div>
                                            <span className="text-[22px] font-[700] block">{i18next.t('button-text.place-bid')}:</span>
                                            <div className="flex justify-between">
                                                {creditLimit && <span onClick={handleCreditLimitAlert} className="text-grey15 cursor-pointer text-[10px] font-[500] flex">{creditLimit?.unlimited ? i18next.t('payment.available-credit-unlimited') : <>{i18next.t('payment.available-credit')}:&nbsp;<Price className="font-[500]" justification="L" number={creditLimit.available_limit} /></>}</span>}
                                                {endTime && <span className="text-[10px] font-[500]"><BidTimer dueDate={endTime} /></span>}
                                            </div>
                                        </div>
                                        {hasUserAuctionAccess && auction?.type === Auction.TypeEnum.Auction && (
                                            <>
                                                <div>
                                                    <div className="flex justify-center">
                                                        <div className="flex-1">
                                                            <div className={clsx('relative flex justify-between flex-col mb-4 mt-2 py-2 items-center')}>
                                                                <motion.span
                                                                    animate={{ color: (bidTooLow || bidExceedsLimit) ? '#D92D20' : '#000000' }}
                                                                    className={clsx(' text-[11px] font-[500] uppercase text-center block')}>
                                                                    {bidTooLow ? i18next.t('payment.bid-too-low') : bidExceedsLimit ? i18next.t('payment.bid-exceeded-limit') : i18next.t('common:item-text.current-bid-text.your-bid')}
                                                                </motion.span>
                                                                <motion.input
                                                                    animate={{ borderColor: (bidTooLow || bidExceedsLimit) ? '#D92D20' : '#D0D5DD', color: (bidTooLow || bidExceedsLimit) ? '#D92D20' : '#000000' }}
                                                                    placeholder="$12,345"
                                                                    style={{ borderBottom: '1px solid #D0D5DD' }}
                                                                    disabled={loading}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        setCustomBid(true);
                                                                        setCustomBidValue(e.target.value);
                                                                    }}
                                                                    value={(customBidValue || suggestedBidStatic) ?? ''}
                                                                    className={clsx('w-full text-center text-[24px] font-[500] pb-[5px]')}
                                                                    autoFocus
                                                                />
                                                                <div className="mt-[10px]">
                                                                    <span className="text-[10px] flex font-[500] text-grey15 uppercase leading-[12px]">{i18next.t('item-text.current-bid-text.current-bid')}:&nbsp; <Price className={clsx('text-[12px] font-[500] text-black block')} state={isWinning === true ? 'positive' : isWinning === false ? 'negative' : undefined} justification="L" number={auction?.current_amount ?? '0'} /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {[Auction.StatusEnum.Open, Auction.StatusEnum.Extended].includes(auction?.status as Auction.StatusEnum) && auction?.type === Auction.TypeEnum.Auction && (
                                                        <div className="flex gap-4">
                                                            {auction?.min_next_bids.map((bid) => {
                                                                return (
                                                                    <button
                                                                        key={bid}
                                                                        onClick={() => {
                                                                            setCustomBidValue(format(Number(bid).toFixed(0).toString() ?? '0'));
                                                                            setCustomBid(true);
                                                                        }}
                                                                        className="text-xs md:text-sm w-full flex flex-row justify-center mb-[15px] border-[1px] rounded-full px-[10px] md:px-[16px] py-[10px] relative cursor-pointer hover:bg-grey16">
                                                                        <Price className="@[600px]:text-[1.375rem] text-[1rem] font-[700]" justification="L" number={bid} />
                                                                    </button>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <span className="text-grey15 text-[10px] font-[500] uppercase">{i18next.t('button-text.payment-method')}</span>
                                                    {renderPaymentMethods()}
                                                </div>
                                                {placebidButton}
                                                <span className="font-[500] text-[10px] text-center block cursor-pointer uppercase"><Link href={Constants.Support.BIDDING_PROCESS} className="underline" target="_blank">{i18next.t('item-text.how-bidding-works')}</Link></span>
                                            </>
                                        )}
                                    </div>
                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default ProductBidModal;
