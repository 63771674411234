import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x.svg';
import Arrow from 'public/assets/icons/chevron-right-long.svg';
import { Item } from '@/api';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import SmallLogo from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import { useMutation } from '@tanstack/react-query';
import { setItemMinOfferPriceFn } from '@/queries/offer';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import toast from 'react-hot-toast';
import Lottie from 'lottie-react';
import i18next from 'i18next';
import SuccessAnimation from 'public/assets/animations/success2.json';
import useOffers from '@/hooks/useOffers';
import THDialogWrapper from '../../../general/THDialogWrapper';
import Button from '../../../general/Button';

type ProductOfferProps = {
    onHide: () => void;
    show: boolean;
    product: Item | undefined;
    onChange?: (amount: string) => void
};

const MinimumOfferSetModal = (props: ProductOfferProps) => {
    const { onHide, product, show, onChange } = props;
    const [customBidValue, setCustomBidValue] = useState<number>(Number(product?.min_offer_amount) || 0);
    const [success, setSuccess] = useState<boolean>(false);
    const [successAnimation, setSuccessAnimation] = useState<object>();
    const [successTitle, setSuccessTitle] = useState<string>('');
    const [successContent, setSuccessContent] = useState<string>('');
    const [offerString, setOfferString] = useState<string>(`$${(Number(product?.min_offer_amount)).toFixed()}`);
    const { invalidateQueries } = useOffers(product, false, false);

    const reset = () => {
        setTimeout(() => {
            setSuccess(false);
            setSuccessTitle('');
            setSuccessContent('');
        }, 350);
    };

    useEffect(() => {
        reset();
        return reset;
    }, []);

    const setMinOfferMutation = useMutation({
        mutationFn: setItemMinOfferPriceFn,
        onSuccess: (response) => {
            setSuccess(true);
            onChange?.(response.min_offer_amount);
            invalidateQueries();
            if (response) {
                setSuccessTitle(i18next.t('common:offers.minimum-offer-set'));
                setSuccessContent(i18next.t('common:offers.minimum-offer-set-notification', { amount: response.min_offer_amount }));
                setSuccessAnimation(SuccessAnimation);
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            toast.error(transformedError.message);
        }
    });

    return (
        <Transition.Root show={show} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-1000"
                onClose={() => {
                    onHide();
                    reset();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity z-[100]" />
                </Transition.Child>
                <div className="fixed z-[100] overflow-y-auto right-[0px] lg:right-[30px] lg:top-[0px] top-[0px] bottom-[0px] lg:bottom-[0px] left-[0px] lg:left-[50%]">
                    <div className="flex min-h-full items-end justify-end p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative  transform  rounded-[8px] bg-white shadow-xl transition-all w-full max-w-[100vw] lg:max-w-[40vw] lg:h-[90vh] h-[100vh] ">
                                <>
                                    <button
                                        className="cursor-pointer"
                                        onClick={() => {
                                            reset();
                                            onHide();
                                            setCustomBidValue(Number(Number(product?.min_offer_amount).toFixed(0)));
                                        }}><XIcon className="absolute right-[15px] top-[26px] scale-[0.8]" />
                                    </button>
                                    {success &&
                                        <button
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setSuccess(false);
                                            }}><Arrow className="absolute left-[15px] top-[28px] scale-[1.5] rotate-180" />
                                        </button>
                                    }
                                    <div className="text-center border-b-[1px] border-grey13">
                                        <h3 className="text-[20px] font-[500] pb-[22px]">{i18next.t('common:offers.set-minimum-price')}</h3>
                                    </div>
                                    <AnimatePresence mode="popLayout" />
                                    {!success &&
                                        <div className="space-y-[20px] p-[40px] flex flex-col justify-between h-[93%]">
                                            <div className="gap-[32px] flex flex-col">
                                                <div>
                                                    <span className="text-[24px] font-[500] block">{i18next.t('common:offers.set-minimum-price')}:</span>
                                                    <span className="text-[18px] font-[400] block text-grey15 mt-[8px]">{i18next.t('common:offers.set-minimum-price-text')}</span>
                                                </div>

                                                <div className="flex flex-row items-center gap-[16px]">
                                                    {(!product?.images || !product?.images[0].url) ?
                                                        <SmallLogo className="cursor-pointer lg-tablet:h-[80px] h-[80px] w-[80] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] lg-tablet:min-w-[80px] block p-12 [&>path]:fill-blue2" /> :
                                                        <ResponsiveImage src={product?.images[0].url} imageWidth={80} width={80} height={80} className="cursor-pointer lg-tablet:h-[80px] h-[120px] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] w-full lg-tablet:min-w-[80px] block rounded-[8px]" />}
                                                    <div>
                                                        <h3 className="text-[16px] font-[500]">{product?.name}</h3>
                                                        {product?.artists && product.artists.length > 0 && <span className="text-[14px] font-[500] text-grey15">{product?.artists[0].name}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex justify-center">
                                                    <div className="flex-1">
                                                        <div className={clsx('relative flex justify-between flex-col mb-4 mt-2 py-2 items-center')}>
                                                            <motion.span
                                                                animate={{ borderColor: '#D0D5DD', color: '#000000' }}
                                                                className={clsx(' text-[11px] font-[500] text-center block uppercase')}>
                                                                {`${i18next.t('common:offers.minimum-offer-price')}`}
                                                            </motion.span>
                                                            <motion.input
                                                                style={{ borderBottom: '1px solid #D0D5DD' }}
                                                                animate={{ borderColor: '#D0D5DD', color: '#000000' }}
                                                                placeholder="$12,345"
                                                                onChange={(e) => {
                                                                    setOfferString(typeof e.target.value === 'number' ? `$${Number(e.target.value).toFixed()}` : !e.target.value.includes('$') ? `$${e.target.value}` : `${e.target.value}`);
                                                                    setCustomBidValue(Number(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')));
                                                                }}
                                                                value={offerString ?? ''}
                                                                className={clsx('w-full text-center text-[24px] font-[500] pb-[5px]')}
                                                                autoFocus
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <Button
                                                    onClick={() => {
                                                        setMinOfferMutation.mutate({ id: product?.id ?? '', payload: { amount: String(customBidValue.toFixed(2)) ?? 0 } });
                                                    }}
                                                    loading={setMinOfferMutation.isLoading}
                                                    disabled={!customBidValue}
                                                    fullWidth>{i18next.t('common:offers.confirm')}
                                                </Button>
                                            </div>

                                        </div>
                                    }

                                    {success &&
                                        <motion.div
                                            className="h-[93%]"
                                            transition={{ ease: 'easeOut' }}
                                            key="c"
                                            animate={{ x: 0 }}
                                            initial={{ x: '200%' }}
                                            exit={{ x: '-200%' }}
                                        >
                                            <div className="space-y-[20px] p-[40px] flex flex-col justify-between h-[93%]">
                                                <div className="h-full my-auto">
                                                    <div className="gap-[32px] flex flex-col items-center text-center max-w-[360px] w-full mx-auto">
                                                        <Lottie style={{ width: 150, height: 150 }} animationData={successAnimation} loop={false} />
                                                        <h3 className="text-[32px] text-[500] font-[500]">{successTitle}</h3>
                                                        <span className="text-[18px] text-[400] leading-[32px] text-grey15 tracking-[-0.2px]">{successContent}</span>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col gap-[16px]">
                                                    <Button
                                                        onClick={() => {
                                                            reset();
                                                            onHide();
                                                        }}
                                                        fullWidth>{i18next.t('common:offers.close')}
                                                    </Button>
                                                </div>

                                            </div>
                                        </motion.div>
                                    }

                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default MinimumOfferSetModal;
