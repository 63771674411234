export enum Locales {
    'English' = 'en',
    'English (Australia)' = 'en-AU',
    'English (Canada)' = 'en-CA',
    'English (United Kingdom)' = 'en-GB',
    'English (United States)' = 'en-US',
    'Swiss German' = 'gsw',
    'Swiss German (Switzerland)' = 'gsw-CH',
    'Portuguese (Brazil)' = 'pt-BR',
    'Portuguese' = 'pt',
}
