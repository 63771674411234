import { AddressDto, NewsletterDto, UpdateUserDto } from '@/api';
import { userApi } from './auth';

export const createAddressQueryFn = async (data: AddressDto) => {
    return userApi.userControllerCreateAddress(data);
};

export const updateAddressQueryFn = async (data: {
    data: AddressDto;
    id: string;
}) => {
    return userApi.userControllerUpdateAddress(data.data, data.id);
};

export const getUserAddressesQueryFn = async () => {
    return userApi.userControllerGetAddresses();
};

export const updateMeFn = async (data: UpdateUserDto) => {
    return userApi.userControllerUpdateMe(data);
};

export const getUserCreditLimitFn = async (auctionId?: string) => {
    return userApi.userControllerGetCreditLimit(auctionId);
};

export const getUserCreditHistoryFn = async () => {
    return userApi.userControllerGetCreditLimitHistory();
};

export const subscribeNewsletterFn = async (data: NewsletterDto) => {
    return userApi.userControllerSubscribeNewsletter(data);
};
