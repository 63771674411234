import { Offer } from '@/api';
import DateFormat from '@/components/general/DateFormat';
import Price from '@/components/general/Price';
import i18next from 'i18next';
import React from 'react';
import XIcon from 'public/assets/icons/x.svg';

const AllOffersView = ({ offers, showX, onClose }: { offers: Offer[] | undefined, showX?: boolean, onClose?: () => void }) => {
    return (
        <div className="bg-grey16 w-full rounded-[8px] p-[24px] h-[75%]">
            <div className="flex flex-row justify-between items-center w-full">
                <h3 className="text-[20px] font-[500] text-black mb-[32px]">{i18next.t('common:offers.recent-offers')}</h3>
                {showX &&
                    <button
                        className="cursor-pointer mb-[32px]"
                        onClick={() => onClose && onClose()}><XIcon className="scale-[1]" />
                    </button>
                }
            </div>

            <div className="h-[100%]">
                <div className="flex flex-row w-full justify-between text-[12px] font-[500] text-grey15 pb-[8px]">
                    <span className="w-[60%]">{i18next.t('common:offers.amount')}</span>
                    <span className="w-[40%]">{i18next.t('common:offers.time')}</span>
                </div>
                <div className="h-[90%] overflow-scroll">
                    {(offers)?.map((offer) => {
                        return (
                            <div key={`OfferHistoryItem-${offer.id}`} className="flex flex-row justify-between  border-t-[1px] border-grey14 py-[20px] items-center">
                                <span className="w-[60%] text-black font-[500] text-[16px]"><Price className="text-[18px] font-[600]" justification="L" number={offer?.amount ?? '0'} /></span>
                                <span className="w-[40%] text-[14px] font-[400] text-grey15"><DateFormat customMask="mm/dd/yyyy h:MMtt" date={offer?.created_at ?? ''} />{new Date(offer.expires_at).getTime() < Date.now() ? ` (${i18next.t('common:offers.expired')})` : ''}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AllOffersView;
