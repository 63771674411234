import { PaymentApi } from '@/api';
import { apiConfiguration } from '@/api/config';

export const paymentApi = new PaymentApi(apiConfiguration);

export const getCardSetupSecretFn = async () => {
    return paymentApi.paymentControllerSetupCard();
};

export const getBankSetupSecretFn = async () => {
    return paymentApi.paymentControllerSetupBank();
};

export const getUserPaymentMethodsFn = async () => {
    return paymentApi.paymentControllerGetPaymentMethods();
};

export const getStripeConnectLinkFn = async () => {
    return paymentApi.paymentControllerGetConncetedAccountLink();
};
