import { ItemApi, OfferApi, SubmitOfferDto, UpdateMinOfferDto } from '@/api';
import { apiConfiguration } from '@/api/config';

export const offerApi = new OfferApi(apiConfiguration);
export const itemApi = new ItemApi(apiConfiguration);

// Only call if item.check_offers_possible === true
export const checkOfferPossibleFn = async (itemId: string) =>
    offerApi.offerControllerCheckOffersPossible(itemId);

type DTO = {
    itemId: string;
    dto: SubmitOfferDto;
};

export const submitOfferFn = async ({ dto, itemId }: DTO) =>
    offerApi.offerControllerSubmitOffer(dto, itemId);

// Valid when offer is not expired and 1) offer.to_owner_id === currentUser.id && offer.status === Offer.StatusEnum.Pending or 2) offer.user_id === currentUser.id && offer.status === Offer.StatusEnum.Countered
export const rejectOfferFn = async (offerId: string) =>
    offerApi.offerControllerRejectOffer(offerId);

// Valid when offer is not expired and 1) offer.to_owner_id === currentUser.id && offer.status === Offer.StatusEnum.Pending or 2) offer.user_id === currentUser.id && offer.status === Offer.StatusEnum.Countered
export const acceptOfferFn = async (offerId: string) =>
    offerApi.offerControllerAcceptOffer(offerId);

// Valid when offer is not expired and offer.to_owner_id === currentUser.id && offer.status === Offer.StatusEnum.Pending. Min Amount is offer.amount + 1.00
export const counterOfferFn = async ({ dto, itemId }: DTO) =>
    offerApi.offerControllerCounterOffer(dto, itemId);

export const getCurrentOfferFn = async (itemId: string) =>
    offerApi.offerControllerGetCurrentOffer(itemId).then((res) => res || null);

export const getAllOffersFn = async (itemId: string, includeExpired: boolean) =>
    offerApi.offerControllerGetAllOffers(itemId, includeExpired);

export const getInTransactionItems = async () =>
    offerApi.offerControllerGetItemsInTransaction();

export const getUserLatestOfferPerItem = async (includeExpired?: boolean) =>
    offerApi.offerControllerGetUserLatestOfferPerItem(includeExpired);

export const getOwnerLatestOfferPerItem = async (includeExpired?: boolean) =>
    offerApi.offerControllerGetOwnerLatestOfferPerItem(includeExpired);

export const setItemMinOfferPriceFn = async (data: {
    payload: UpdateMinOfferDto;
    id: string;
}) => itemApi.itemControllerUpdateMinOfferAmount(data.payload, data.id);

export const getShippingLabelFn = async (id: string) => offerApi.offerControllerGetTransactionShippingLabel(id);
